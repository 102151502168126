import React from 'react';
import '../styles/Contact.css';
import MetaTags from '../components/MetaTags';

const ContactUs = () => {
  return (
    <div className="contact-us-container">
      <h1>Contact Us</h1>

      <MetaTags
        title={`Contact Us - Placement Master`}
        description={`Your satisfaction is our top priority. Reach out to us with any questions or concerns, and we'll make sure to assist you as quickly as possible`}
        images={`https://placementmaster.in/assets/img/logo.png`}
        url={`https://shop.placementmaster.in/contact`}
      />

      <div className="contact-us-content">
        <div className="contact-us-text">
          <p>
            At <strong>Placement Master</strong>, our goal is to offer you the best shipping options, no matter where you live!
          </p>

          <h2>Shipping & Delivery</h2>
          <p>
            By purchasing from <strong>Placement Master</strong>, you agree to our Shipping Policy. Please note that we will not be held responsible for lost goods if an incorrect shipping address is provided.
          </p>

          <p>
            Should your package not show up, or if it arrives damaged, please contact us immediately at:
          </p>

          <p className="contact-info">
            📧 <strong>support@placementmaster.in</strong>
          </p>

          <h3>Our Support Staff</h3>
          <p>
            Our Support Staff is working 7 days a week to handle any requests. Please contact us using the form on this page, or reach out to us at the provided email.
          </p>

          <h3>We Are Here to Help!</h3>
          <p>
            Your satisfaction is our top priority. Reach out to us with any questions or concerns, and we'll make sure to assist you as quickly as possible.
          </p>
        </div>

        <div className="contact-us-images">
          <img src={'https://cdn.shopify.com/s/files/1/0590/7873/8097/files/signing-shipping-forms-for-large-box_720x.jpg?v=1628863960'} alt="Delivery" className="contact-us-image" />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;

import React from 'react';
import '../styles/SalesBanner.css'; 

const SalesBanner = () => {
  return (
    <div className="sales-banner">
      <div className="banner-content">
        <p className="banner-message">🔥 Big Summer Sale! Get 25% Off All Items!</p>
        <a href="/shop" className="banner-button">Shop Now</a>
      </div>
    </div>
  );
};

export default SalesBanner;

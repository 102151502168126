import React from 'react';
import '../styles/PrivacyPolicy.css'; 
import MetaTags from '../components/MetaTags';

const TermsAndConditions = () => {
  return (
    <div className="privacy-container">
      <MetaTags
        title={`Terms and Conditions - Placement Master`}
        description={`Welcome to Placement Master! By using our website and purchasing our digital products, you agree to comply with and be bound by the following terms and conditions. Please review them carefully.`}
        images={`https://placementmaster.in/assets/img/logo.png`}
        url={`https://shop.placementmaster.in/terms-conditions`}
      />
      <h1>Terms and Conditions</h1>

      <p>Welcome to Placement Master! By using our website and purchasing our digital products, you agree to comply with and be bound by the following terms and conditions. Please review them carefully.</p>

      <h2>1. Introduction</h2>
      <p>These terms and conditions govern your use of Placement Master’s website and the purchase of digital products from our platform. By using our website, you accept these terms in full. If you disagree with any part of these terms, you must not use our website.</p>

      <h2>2. Digital Products</h2>
      <p>All products available on Placement Master are digital downloads. Upon successful payment, you will receive access to the purchased product. Ensure that you download and store your purchased items securely, as access may be time-limited.</p>

      <h2>3. Refund Policy</h2>
      <p>Due to the nature of digital products, all sales are final. We do not offer refunds, exchanges, or cancellations. Please ensure that you review the product description carefully before making a purchase.</p>

      <h2>4. Intellectual Property</h2>
      <p>All content, including digital products, logos, text, graphics, and images, is the intellectual property of Placement Master or its licensors. You may not reproduce, distribute, or sell any content from our website without prior written permission.</p>

      <h2>5. Limitation of Liability</h2>
      <p>Placement Master shall not be liable for any damages that result from the use of, or inability to use, the materials on our website. This includes, without limitation, damages for loss of data or profit.</p>

      <h2>6. Governing Law</h2>
      <p>These terms and conditions are governed by and construed in accordance with the laws of India, and any disputes relating to these terms will be subject to the exclusive jurisdiction of the courts of India.</p>

      <h2>7. Changes to Terms</h2>
      <p>Placement Master reserves the right to revise these terms and conditions at any time. Any changes will be posted on this page, and it is your responsibility to review the terms regularly.</p>

      <p>If you have any questions or concerns regarding these terms and conditions, please contact us at support@placementmaster.in.</p>
    </div>
  );
};

export default TermsAndConditions;

import React from 'react';
import '../styles/About.css';
import MetaTags from '../components/MetaTags';

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <h1>About Us</h1>

      <MetaTags
        title={`About Us - Placement Master`}
        description={`Placement Master, we are dedicated to helping students prepare for placements and secure their dream jobs at top companies.`}
        images={`https://placementmaster.in/assets/img/logo.png`}
        url={`https://shop.placementmaster.in/about`}
      />

      <div className="about-us-content">
        <div className="about-us-text">
          <p>
            At <strong>Placement Master</strong>, we are dedicated to helping students prepare for placements and secure their dream jobs at top companies.
          </p>

          <p>
            Our platform is designed to assist students in honing their skills and getting placed in top IT and non-IT companies across India. We believe that every student has unique talents and skills, and we are here to help you achieve your goals.
          </p>

          <h2>Our Vision</h2>
          <p>
            Whether you're from an IT or non-IT background, if you're passionate about learning and working in an IT-based industry, <strong>Placement Master</strong> is the perfect platform for you. We strive to help you accomplish the dreams you've always pursued.
          </p>

          <p>
            Many of our users have successfully secured positions in top companies like TCS, CTS, IBM, Accenture, and many more, thanks to our comprehensive resources.
          </p>

          <h3>Join Us</h3>
          <p>
            Become part of the <strong>Placement Master</strong> family and get instant updates on new materials, job opportunities, and placement drives across various companies. Your dream job is just a step away!
          </p>

          <p className="contact-info">
            For more information, feel free to contact us at: 📧 <strong>support@placementmaster.in</strong>
          </p>

        </div>
      </div>

      <div className="about-us-images">
        <img src={'https://cdn.shopify.com/s/files/1/0590/7873/8097/files/collecting-order-at-curbside-from-retail-store_3750x.jpg?v=1628523830'} alt="About Us" className="about-us-image" />
      </div>
    </div>
  );
};

export default AboutUs;

import { ThumbUp, Lock, LocalShipping } from '@mui/icons-material';
import React from 'react';
import '../styles/TrustTag.css'; 

const TrustTag = ({ variant = 'default' }) => {
    return <div className={`trust-info ${variant}`}>
        <div className="info-item">
            <Lock className="info-icon" />
            <p className="info-text">Secure Payments</p>
        </div>
        <div className="info-item">
            <ThumbUp className="info-icon" />
            <p className="info-text">Great Value & Quality</p>
        </div>

        <div className="info-item">
            <LocalShipping className="info-icon" />
            <p className="info-text">Shipping in India</p>
        </div>
    </div>
}

export default TrustTag;

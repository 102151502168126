import { Home, ShoppingCart, ContactMail, Info, Business  } from '@mui/icons-material';

export const MENU_ITEMS = [
  {
    icon: Home,
    label: 'Home',
    path: '/',
  },
  {
    icon: ShoppingCart,
    label: 'Shop',
    path: '/shop',
  },
  {
    icon: Business,
    label: 'MNC',
    path: '/question-paper',
    subMenu: true,
  },
  {
    icon: ContactMail,
    label: 'Contact',
    path: '/contact',
  },
  {
    icon: Info,
    label: 'About',
    path: '/about',
  },
];

import React from 'react';
import { Facebook, Twitter, Instagram, LinkedIn } from '@mui/icons-material';
import '../styles/Footer.css'; // Import your custom styles

const paymentIcons = {
  visa: '../../../UPI.jpeg',
  mastercard: '../../../creditcard.webp',
  americanExpress: '../../../phonepe.png',
  paytm: '../../../paytm.png',
  paypal: '../../../gpay.jpg'
};

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="footer-logo-description">
          <img src="https://placementmaster.in/assets/img/logo.png" alt="Logo" className="footer-logo-img" />
          <p className="footer-description">
          Placement Master: India's Trusted and Leading Placement Portal - Access a Vast Collection of Frequently Asked Aptitude Questions with Detailed Solutions!
          </p>
        </div>
        <div className="footer-links">
          <div className="footer-link-section">
            <h4>Services</h4>
            <ul>
              <li><a href="/faqs">FAQ's</a></li>
              <li><a href="/privacy-policy">Privacy Policy</a></li>
              <li><a href="/terms-conditions">Terms & Conditions</a></li>
              <li><a href="/about">About Us</a></li>
            </ul>
          </div>
          <div className="footer-link-section">
            <h4>Customer Care</h4>
            <ul>
              <li><a href="/warranty-policy">Warranty Policy</a></li>
              <li><a href="/return-exchange">Return & Exchange</a></li>
              <li><a href="/faqs">FAQ's</a></li>
              <li><a href="/contact">Contact Us</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-bottom-layout">
        <div className="footer-payment-methods">
          <h4>We Accept</h4>
          <div className="footer-payment-icons">
            <img src={paymentIcons.visa} alt="Visa" className="payment-icon" />
            <img src={paymentIcons.mastercard} alt="Mastercard" className="payment-icon" />
            <img src={paymentIcons.americanExpress} alt="American Express" className="payment-icon" />
            <img src={paymentIcons.paytm} alt="Paytm" className="payment-icon" />
            <img src={paymentIcons.paypal} alt="Paypal" className="payment-icon" />
          </div>
        </div>
        <div className="footer-bottom">
          <div className="footer-social-media">
            <a href="https://www.facebook.com/placementmaster/" target="_blank" className="facebook-icon" rel="noopener noreferrer">
              <Facebook />
            </a>
            <a href="https://x.com/PlacementM?s=09" target="_blank" className="twitter-icon" rel="noopener noreferrer">
              <Twitter />
            </a>
            <a href="https://www.instagram.com/placementmaster/?hl=en" target="_blank" className="instagram-icon" rel="noopener noreferrer">
              <Instagram />
            </a>
            <a href="https://www.linkedin.com/company/placement-master" target="_blank" className="linkedin-icon" rel="noopener noreferrer">
              <LinkedIn />
            </a>
          </div>
          <p className="footer-copy">
            &copy; {new Date().getFullYear()} Placement Master. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

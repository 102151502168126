import React from 'react';
import '../styles/ProductPage.css'; // Import CSS file

const ProductDetails = ({ title, description, tags, details, discountPrice, originalPrice, id }) => {
    return (
        <div className="product-details">
            <h1>{title}</h1>
            <p className="product-description" dangerouslySetInnerHTML={{ __html: description }} />
            <div className="product-pricing">
                <span className="discount-price">₹{discountPrice}</span>
                <span className="original-price">₹{originalPrice}</span>
            </div>

            <div className="product-tags">
                {tags.map((tag, index) => (
                    <span key={index} className="product-tag">{tag}</span>
                ))}
            </div>

            {/* Buy Button */}
            <div className="product-buy">
                <a
                    href='https://placementmaster.myinstamojo.com'
                    data-store-name="placementmaster"
                    data-domain="https://placementmaster.myinstamojo.com"
                    data-id={id}
                    rel="im-new-checkout"
                    data-text="Buy Now"
                    data-css-style="background:#f58020; color:#ffffff; width:180px; border-radius:30px"
                >
                    Buy Now
                </a>
            </div>

            <div className="product-details-info">
                <p dangerouslySetInnerHTML={{ __html: details }} />
            </div>
        </div>
    );
};

export default ProductDetails;

import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { DeliveryDiningOutlined, MoneyOff, SupportAgent } from '@mui/icons-material'; 
import '../styles/HomePage.css'; 
import { companyPlacementPapers, assessmentTests, competitiveExams } from '../data/products'; 
import MetaTags from '../components/MetaTags';

// Reusable ProductSection component
const ProductSection = ({ title, items }) => (
  <div className="product-section">
    <h2>{title}</h2>
    <div className="products-list">
      {items.map((item, index) => (
        <a key={index} href={item.path} className="product">
          <h3>{item.name}</h3>
          <p>Get the latest materials for {item.name}.</p>
        </a>
      ))}
    </div>
  </div>
);

const HomePage = () => {
  return (
    <div className="home-page">

      <MetaTags
        title={`Home - Placement Master`}
        description={`Browse our collection of placement papers, assessment tests, and competitive exam preparation materials!`}
        images={`https://placementmaster.in/assets/img/logo.png`}
        url={`https://shop.placementmaster.in/home`}
      />
      {/* Welcome Section */}
      <div className="welcome-section">
        <h1>Welcome to the Placement Master Store</h1>
        <p>Browse our collection of placement papers, assessment tests, and competitive exam preparation materials!</p>
      </div>

      {/* Banner Section */}
      <div className="banner">
        <Carousel autoPlay infiniteLoop showThumbs={false}>
          <div>
            <img src="../../../banner1.jpg" alt="Banner 1" />
          </div>
          <div>
            <img src="../../../banner2.jpg" alt="Banner 2" />
          </div>
          <div>
            <img src="../../../banner3.png" alt="Banner 3" />
          </div>
        </Carousel>
      </div>

      {/* Services Section */}
      <div className="services">
        <div className="service">
          <DeliveryDiningOutlined className="service-icon" />
          <h3>Free Delivery</h3>
          <p>We will ship free if your order exceeds ₹ 500</p>
        </div>
        <div className="service">
          <MoneyOff className="service-icon" />
          <h3>Money Back</h3>
          <p>If goods have a problem, we'll return your money.</p>
        </div>
        <div className="service">
          <SupportAgent className="service-icon" />
          <h3>24/7 Support</h3>
          <p>Our dedicated support is available 24/7.</p>
        </div>
      </div>

      {/* Company Placement Papers Section */}
      <ProductSection title="Company Placement Papers" items={companyPlacementPapers} />

      {/* Assessment Tests Section */}
      <ProductSection title="Assessment Tests" items={assessmentTests} />

      {/* Competitive Exams Preparation Section */}
      <ProductSection title="Competitive Exams Preparation" items={competitiveExams} />
    </div>
  );
};

export default HomePage;

import React, { useState } from 'react';
import { products } from '../data/products';
import { Link } from 'react-router-dom';
import SearchBar from '../components/SearchBar';
import CategoryFilter from '../components/CategoryFilter';
import SortOptions from '../components/SortOptions';
import Pagination from '../components/Pagination';
import '../styles/ProductListing.css';

const ProductList = () => {
  const [search, setSearch] = useState('');
  const [category, setCategory] = useState('All');
  const [sort, setSort] = useState('default');
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 10;

  const filteredProducts = products.filter(product => 
    (category === 'All' || product.category === category) &&
    product.title.toLowerCase().includes(search.toLowerCase())
  );

  const sortedProducts = filteredProducts.sort((a, b) => {
    if (sort === 'priceAsc') return parseFloat(String(a.discountPrice).slice(1)) - parseFloat(String(b.discountPrice).slice(1));
    if (sort === 'priceDesc') return parseFloat(String(b.discountPrice).slice(1)) - parseFloat(String(a.discountPrice).slice(1));
    return 0;
  });

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = sortedProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  return (
    <div className="product-list">
      <div className="filters">
        <SearchBar search={search} setSearch={setSearch} />
        <CategoryFilter category={category} setCategory={setCategory} />
        <SortOptions sort={sort} setSort={setSort} />
      </div>
      <div className="products">
        {filteredProducts.length === 0 ? (
          <div className="no-records">
            <img src="../../notfound.avif" alt="No Records Found" />
            <p>No records found</p>
          </div>
        ) : (
          currentProducts.map(product => (
            <div key={product.id} className="product-box">
              <img src={product.image} alt={product.title} />
              <h3>{product.title}</h3>
              <div className="price">
                {product.discountPrice ? (
                  <>
                    <span className="discount-price">{product.discountPrice}</span>
                    <span className="original-price">{product.price}</span>
                  </>
                ) : (
                  <span className="discount-price">{product.price}</span>
                )}
              </div>
              <Link to={`/question-paper${product.path}`}>
                <button>Buy</button>
              </Link>
            </div>
          ))
        )}
      </div>
      {filteredProducts.length > 0 && (
        <Pagination currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage} />
      )}
    </div>
  );
};

export default ProductList;

import React from 'react';

const SortOptions = ({ sort, setSort }) => (
  <select onChange={(e) => setSort(e.target.value)} value={sort}>
    <option value="default">Default</option>
    <option value="priceAsc">Price: Low to High</option>
    <option value="priceDesc">Price: High to Low</option>
  </select>
);

export default SortOptions;

import { AccountBalance, Code, Business } from '@mui/icons-material';

export const MNC_COMPANIES = [
  { name: 'TCS', icon: AccountBalance },
  { name: 'Infosys', icon: Code },
  { name: 'Wipro', icon: Business },
  { name: 'HCL Technologies', icon: Business },
  { name: 'Tech Mahindra', icon: Business },
  { name: 'CTS', icon: Business },
  { name: 'Capgemini', icon: Business },
  { name: 'Mindtree', icon: Business },
  { name: 'L&T Infotech', icon: Business },
  { name: 'Mphasis', icon: Business },
  { name: 'Oracle Financial Services Software', icon: Business },
  { name: 'IBM India', icon: Business },
  { name: 'Accenture', icon: Business },
  { name: 'Deloitte', icon: Business },
  { name: 'NTT DATA', icon: Business },
  { name: 'PwC', icon: Business },
  { name: 'Happiest Minds Technologies', icon: Business },
  { name: 'Virtusa', icon: Business }
];

import React from 'react';
import Slider from 'react-slick';
import '../styles/ProductPage.css'; // Custom CSS for styling

const ProductImageSection = ({ images, title }) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <div className="product-image-section">
            <div className="image-slider">
                {images.length > 1 ? (
                    <Slider {...settings}>
                        {images.map((image, index) => (
                            <div key={index} className="slide">
                                <img src={image} alt={`Product ${index + 1}`} className="product-image" />
                            </div>
                        ))}
                    </Slider>
                ) : (
                    <img src={images[0]} alt={title} className="product-image" />
                )}
            </div>
        </div>
    );
};

export default ProductImageSection;
